<template>
  <base-layout title="Store">
    <ion-list>
        <ion-item>
          Number of Order Items Today: {{numberOfItemsOrderedToday}}
        </ion-item>
        <ion-item>
            Max Orders: <ion-input type="number" :value="this.maxItemsPerDay" v-model="maxItemsPerDay"></ion-input>
        </ion-item>
        <ion-item>
            Max Items Per Order: <ion-input type="number" :value="this.maxItemsPerOrder" v-model="maxItemsPerOrder"></ion-input>
        </ion-item>
        <ion-item>
            Store Open: <ion-toggle v-model="storeOpen"></ion-toggle>
        </ion-item>
    </ion-list>
    <ion-button router-link="/tabs/stock" color="dark"><ion-icon :icon="storefrontOutline" style="margin-right: 5px;" /> Stock</ion-button>
    <ion-button router-link="/tabs/report" color="dark"><ion-icon :icon="receiptOutline" style="margin-right: 5px;" /> Report</ion-button>
    <ion-button router-link="/tabs/users" color="dark"><ion-icon :icon="peopleCircleOutline" style="margin-right: 5px;" /> Users</ion-button>
    <ion-button @click='saveStore'>Save</ion-button>
  </base-layout>
</template>

<script>
import { ellipse, square, triangle, readerOutline, clipboardOutline, cartOutline, checkboxOutline, storefrontOutline, peopleCircleOutline, timeOutline, receiptOutline } from 'ionicons/icons';

export default  {
  name: 'Store',
  methods: {
      saveStore() {
        console.log(this.maxItemsPerDay);
        console.log(this.maxItemsPerOrder);
        console.log(this.storeOpen);
        this.$store.dispatch('toggleStoreOpen', this.storeOpen);
        this.$store.dispatch('toggleMaxOrders', this.maxItemsPerDay);
        this.$store.dispatch('changeMaxItems', this.maxItemsPerOrder);
      },
      refresh() {
        this.storeOpen = this.store.isOpen;
        this.maxItemsPerDay = this.store.maxOpenOrders;
        this.maxItemsPerOrder = this.store.maxItemsPerOrder;
      }
  },
  computed: {
    store() {
      return this.$store.getters.store;
    },
    numberOfItemsOrderedToday() { 
      return this.$store.getters.numberOfItemsOrderedToday;
    }
  },
  watch: {
    store() {
      this.refresh();
    }
  },
  data() {
    return {
      storeOpen: false,
      maxItemsPerDay: 0,
      maxItemsPerOrder: 0,
      ellipse, 
      square, 
      triangle,
      readerOutline,
      clipboardOutline,
      cartOutline,
      checkboxOutline,
      storefrontOutline,
      peopleCircleOutline,
      timeOutline,
      receiptOutline
    };
  },
  mounted() {
      if (this.store != null)
      {
        this.refresh();
      }
  },
}
</script>